.app__slidingText {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.app__slidingText-container {
  width: auto;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.sliding-content {
  display: flex;
  animation: move 15s linear infinite;
  color: #000;
  padding: 1rem 0;
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.sliding-content span {
  font-size: 8rem;
  white-space: nowrap;
  font-family: var(--font-base);
  color: var(--color-white);
  text-transform: uppercase;
}
@media screen and (min-width: 2000px) {
  .sliding-content span {
    font-size: 10rem;
  }
}
@media screen and (max-width: 1200px) {
  .sliding-content span {
    font-size: 5rem;
  }
}
@media screen and (max-width: 800px) {
  .sliding-content span {
    font-size: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .sliding-content span {
    font-size: 3rem;
  }
}
