.app__about {
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
}
.app__about-title {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem 0rem 6rem;
  gap: 2rem;
  padding-bottom: 0rem;
}
.line {
  height: 1px;
  width: 100%;
  background-color: var(--color-white);
}

.app__about-experience {
  width: 100%;
  height: auto;
  display: flex;
}
.experience-title {
  width: 40% !important;
  height: auto;
}
.experience-description {
  flex: 1;
}
.experience-description h2 {
  font-size: 2rem;
  color: var(--color-white);
  font-family: var(--font-base);
  letter-spacing: 1.5px;
  line-height: 1.5;
  margin-top: 6rem;
}
.experience-description p {
  font-size: 20px;
  color: var(--color-white);
  font-family: var(--font-base);
  letter-spacing: 1.5px;
  line-height: 1.5;
  margin-top: 4rem;
}

@media screen and (max-width: 850px) {
  .app__about-title {
    padding: 3rem 4rem 0rem 4rem;
  }
  .app__about-experience {
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .app__about-title {
    padding: 3rem 2rem 0rem 2rem;
  }
  .experience-description h2 {
    font-size: 1.2rem;
    margin-top: 3rem;
  }
  .experience-description p {
    margin-top: 3rem;
    font-size: 17px;
  }
}
