.app__homeSection-container {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
}
.app__homeSection-container h2 {
  font-size: 6rem;
}
@media screen and (min-width: 2000px) {
  .app__homeSection-container {
    gap: 10rem;
  }
}
@media screen and (max-width: 1000px) {
  .app__homeSection-container {
    gap: 3rem;
  }
}
@media screen and (max-width: 500px) {
  .app__homeSection-container {
    align-items: center;
  }
}
