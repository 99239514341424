.letter {
  font-size: 6rem;
  padding: 0.3rem;
  display: inline-block;
  opacity: 0;
  font-family: var(--font-base);
  text-transform: uppercase;

  /* color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: var(--color-white); */
}
.box {
  overflow: hidden;
}
@media screen and (min-width: 2000px) {
  .letter {
    font-size: 10rem;
  }
}
@media screen and (max-width: 1200px) {
  .letter {
    font-size: 5rem;
  }
}
@media screen and (max-width: 800px) {
  .letter {
    font-size: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .letter {
    font-size: 3rem;
  }
}
