.app__webInt {
  width: 90%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  overflow-x: hidden;
  background-color: var(--color-grey-one);
  margin-bottom: 10rem;
}
.app__webInt-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.webInt-container-left {
  flex: 1;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.webInt-container-left-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 50%;
}
.webInt-container-left-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.scroll-animation {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.scroll-animation.animated {
  opacity: 1;
  transform: translateX(0);
}
.webInt-container-left h2 {
  font-size: 4rem;
  text-transform: capitalize;
  font-family: var(--font-base);
  color: #413f3f;
  z-index: 3;
}
.webInt-container-right {
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.webInt-container-right-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: -50%;
}
.webInt-container-right-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.webInt-container-right p {
  padding: 0 4rem;
  font-size: 22px;
  font-family: var(--font-base);
  color: var(--color-black);
  color: var(--color-grey);
  color: #413f3f;
  font-weight: 500;
  line-height: 1.9;
  z-index: 3;
}
.scroll-animation-right {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.scroll-animation-right.animated {
  opacity: 1;
  transform: translateX(0);
}
@media screen and (max-width: 1200px) {
  .app__webInt {
    height: 85vh;
  }
}
@media screen and (max-width: 1000px) {
  .app__webInt {
    height: 70vh;
    margin-bottom: 7rem;
  }
  .webInt-container-left h2 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 850px) {
  .app__webInt {
    height: 50vh;
  }
  .webInt-container-left h2 {
    font-size: 2rem;
  }
  .webInt-container-right p {
    padding: 0 1rem;
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .app__webInt {
    height: 30vh;
    margin-bottom: 5rem;
  }
  .webInt-container-left-img,
  .webInt-container-right-img {
    width: 115%;
  }
  .webInt-container-left h2 {
    font-size: 1.5rem;
    text-align: center;
  }
  .webInt-container-right p {
    padding: 0 0.5rem;
    font-size: 15px;
    line-height: 1;
  }
}
