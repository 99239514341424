.app__introduction {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}
.app__introduction-box {
  display: flex;
  gap: 2rem;
  padding-top: 7rem;
}
.space {
  flex: 1;
}
.app__introduction-box p {
  flex: 3;
  padding-right: 8rem;
  font-size: 20px;
  line-height: 2.5;
  text-transform: uppercase;
  color: var(--color-white);
  font-family: var(--font-base);
}

@media screen and (max-width: 1000) {
  .app__introduction-box p {
    font-size: 18px;
  }
}
@media screen and (max-width: 850px) {
  .app__introduction-box p {
    padding-right: 0rem;
  }
}
@media screen and (max-width: 500px) {
  .space {
    flex: 0;
    display: none;
  }
  .app__introduction-box {
    padding-top: 4rem;
  }
  .app__introduction-box p {
    font-size: 15px;
    flex: 1;
  }
}
