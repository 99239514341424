.app__singleProject {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 2rem;
  padding: 0.25rem;
}
.sp-new-bg {
  background-color: var(--color-black);
  transition: background 0.3s ease;
}
.sp-initial-bg {
  background-color: var(--color-white);
  transition: background 0.3s ease;
}
.app__singleProject-left {
  flex: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 1rem;
}
.app__singleProject-left h2 {
  font-size: 2.3rem;
  font-family: var(--font-base);
  font-weight: 100;
  text-transform: capitalize;
}
.app__singleProject-left p {
  font-size: 18px;
  font-weight: 100;
  font-family: var(--font-base);
  line-height: 1.2;
}
.text-initial-color {
  color: var(--color-black);
  transition: color 0.5s ease;
}
.text-new-color {
  color: var(--color-white);
  transition: color 0.5s ease;
}
.text-new-color2 {
  color: var(--color-white);
  transition: color 0.5s ease;
}
.app__singleProject-right {
  position: relative;
  min-width: 500px;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  background-color: var(--color-grey);
  padding: 0.3rem;
  overflow: hidden;
}
.app__singleProject-right_container {
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
}
.app__singleProject-right-box {
  min-width: 500px;
  height: 100%;
}
.app__singleProject-right-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease;
}
.app__singleProject-right_container:hover {
  animation: image-translate 5s linear forwards;
  transition: 0.3s ease;
}
@keyframes image-translate {
  to {
    transform: translateX(-2000px);
  }
}
@media screen and (min-width: 2000px) {
  .app__singleProject-left h2 {
    font-size: 2rem;
  }
  .app__singleProject-left p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1200px) {
  .app__singleProject-left h2 {
    font-size: 2rem;
  }
  .app__singleProject-left p {
    font-size: 18px;
  }
  .app__singleProject-right {
    min-width: 350px;
  }
  .app__singleProject-right-box {
    min-width: 350px;
  }
  @keyframes image-translate {
    to {
      transform: translateX(-1400px);
    }
  }
}
@media screen and (max-width: 750px) {
  .app__singleProject {
    width: 100%;
    height: 400px;
    flex-direction: column;
    gap: 0.8rem;
  }
  .app__singleProject-left {
    flex: 0;
  }
  .app__singleProject-right {
    flex: 1;
    min-width: 100%;
    height: 200px;
  }
  .app__singleProject-right-box {
    min-width: 100%;
    flex: 1;
  }
}
@media screen and (max-width: 550px) {
  .app__singleProject-right_container:hover {
    animation: image-translate 5s linear;
  }
  @keyframes image-translate {
    to {
      transform: translateX(-1275px);
    }
  }
}

.scroll-animation-project {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.scroll-animation-project.animated {
  opacity: 1;
  transform: translateY(0);
}
