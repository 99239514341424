@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "CustomFontFamily";
  src: url("./fonts/Redwing-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-base: "CustomFontFamily", serif;
  --font-alt: "Open Sans", sans-serif;
  --font-title: "Cormorant Upright", serif;
  --color-black: #0c0c0c;
  --color-grey-one: #e7e4df;
  --color-white: #ffffff;
  --color-grey: #aaaaaa;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-crimson: #f5efdb;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body {
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
