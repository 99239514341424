.app__header {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
}
.app__header-logo {
  width: auto;
  height: 90%;
}
.app__header-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__header-social_media {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 770px) {
  .app__header {
    width: 100%;
    height: 120px;
  }
  .app__header-logo {
    height: 90%;
  }
}
@media screen and (max-width: 650px) {
  .app__header {
    padding: 0.5rem 2rem;
  }
}
@media screen and (max-width: 480px) {
  .app__header-logo {
    height: 50%;
  }
}
