.app__navbar-opened {
  width: 500px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 0.3rem;
  background-color: var(--color-crimson);
  z-index: 5000;
  transition: width 0.5s ease;
}
.app__navbar {
  width: 300px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 0.3rem;
  background-color: var(--color-crimson);
  z-index: 5000;
  transition: width 0.5s ease;
}
.app__navbar-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.2rem;
}
.app__navbar-container div:nth-of-type(1) {
  width: 32%;
  height: 100%;
  background-color: var(--color-grey);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__navbar-container div:nth-of-type(2) {
  flex: 1;
  height: 100%;
  background-color: var(--color-grey);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__navbar-container h4 {
  font-size: 2rem;
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 100;
}
.app__navbar-toggle-menu {
  width: 500px;
  height: 50vh;
  position: fixed;
  bottom: 65px;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
  background-color: var(--color-crimson);
  border-radius: 10px;
  transition: 1s ease;
  padding: 1rem;
}
.navbar-links {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.navbar-links li {
  list-style: none;
}
.link {
  font-size: 2.5rem;
  font-family: var(--font-base);
  font-weight: 100;
  line-height: 1.2;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.link:hover {
  opacity: 1;
}
.close {
  align-self: flex-end;
  width: 40px;
  height: 40px;
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.close:hover {
  transform: rotate(-45deg);
}
.show {
  transition: 1s ease;
}
.hide {
  visibility: hidden;
}

@media screen and (min-width: 2000px) {
  .app__navbar-opened {
    width: 30%;
  }
  .app__navbar {
    width: 20%;
  }
  .app__navbar-toggle-menu {
    width: 100%;
  }
  .app__navbar-container h4 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 650px) {
  .app__navbar-opened {
    width: 70%;
  }
  .app__navbar {
    width: 50%;
  }
  .app__navbar-toggle-menu {
    width: 100%;
  }
  .app__navbar-container h4 {
    font-size: 1.5rem;
    font-family: var(--font-base);
    color: var(--color-black);
  }
}
@media screen and (max-width: 450px) {
  .app__navbar-opened {
    width: 90%;
  }
  .app__navbar {
    width: 60%;
  }
  .app__navbar-toggle-menu {
    width: 100%;
  }
  .app__navbar-container h4 {
    font-size: 1.5rem;
    font-family: var(--font-base);
    color: var(--color-black);
  }
  .link {
    font-size: 1.8rem;
  }
}
