.app__nativeProject {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 2rem;
  padding: 0.25rem;
}
.sp-new-bg {
  background-color: var(--color-black);
  transition: background 0.3s ease;
}
.sp-initial-bg {
  background-color: var(--color-white);
  transition: background 0.3s ease;
}
.app__nativeProject-left {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 1rem;
}
.app__nativeProject-left h2 {
  font-size: 2.3rem;
  font-family: var(--font-base);
  font-weight: 100;
  text-transform: capitalize;
}
.app__nativeProject-left p {
  font-size: 18px;
  font-weight: 100;
  font-family: var(--font-base);
  line-height: 1.2;
}
.text-initial-color {
  color: var(--color-black);
  transition: color 0.5s ease;
}
.text-new-color {
  color: var(--color-white);
  transition: color 0.5s ease;
}
.text-new-color2 {
  color: var(--color-white);
  transition: color 0.5s ease;
}
.app__nativeProject-right {
  flex: 2;
  min-width: 500px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-grey);
  padding: 0.3rem;
  overflow: hidden;
}
.app__nativeProject-right_container {
  width: 200%;
  height: 100%;
  display: flex;
  position: absolute;
}
.wrapper-content {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: (200px, auto);
  gap: 20px;
}

.nativeProject-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.native-image1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  background-color: #efefef6c;
}
.native-image2 {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  background-color: #efefef6c;
}

.nativeP-img {
  height: 80%;
}
.app__nativeProject-right-box {
  min-width: 500px;
  height: 100%;
}

.app__nativeProject-right_container:hover {
  animation: images-translate 1s linear forwards;
  transition: 0.3s ease;
}
@keyframes images-translate {
  to {
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 2000px) {
  .app__nativeProject-left h2 {
    font-size: 2rem;
  }
  .app__nativeProject-left p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1200px) {
  .app__nativeProject-left h2 {
    font-size: 2rem;
  }
  .app__nativeProject-left p {
    font-size: 18px;
  }
  .app__nativeProject-right {
    min-width: 350px;
  }
  .app__nativeProject-right-box {
    min-width: 350px;
  }
}
@media screen and (max-width: 1000px) {
  .app__nativeProject {
    height: 500px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    gap: 2rem;
    padding: 0.25rem;
  }
  .app__nativeProject-right {
    flex: 2;
    min-width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: var(--color-grey);
    padding: 0.3rem;
    overflow: hidden;
  }
}
@media screen and (max-width: 750px) {
  .app__nativeProject {
    width: 100%;
    height: 400px;
    flex-direction: column;
    gap: 0.8rem;
  }
  .app__nativeProject-left {
    flex: 0;
  }
  .app__nativeProject-right {
    flex: 1;
    min-width: 100%;
    height: 200px;
  }
  .app__nativeProject-right-box {
    min-width: 100%;
    flex: 1;
  }
}
@media screen and (max-width: 550px) {
  .app__nativeProject-right_container:hover {
    animation: images-translate 2s linear;
  }
  @keyframes images-translate {
    to {
      transform: translateX(-50%);
    }
  }
  .nativeP-img {
    height: 65%;
  }
}

.scroll-animation-project {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.scroll-animation-project.animated {
  opacity: 1;
  transform: translateY(0);
}
