.app__recentProjects {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 2rem;
  position: relative;
}
.app__recentProjects-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 3rem;
}
.link_see-more {
  position: relative;
  width: fit-content;
  color: var(--color-white);
  font-size: 1.4rem;
  margin-top: 2rem;
  cursor: pointer;
}
.link_see-more::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -7px;
  background-color: var(--color-crimson);
  transition: width 0.5s ease;
}
.link_see-more:hover.link_see-more::after {
  width: 100%;
}
@media screen and (max-width: 550px) {
  .link_see-more {
    font-size: 1rem;
  }
}
