.p-box {
  overflow: hidden;
}

.p-letter {
  font-size: 4rem;
  /* padding: 0.3rem; */
  display: inline-block;
  opacity: 0;
  /* font-family: var(--font-base); */
  text-transform: uppercase;

  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: var(--color-white);
}

@media screen and (min-width: 2000px) {
  .p-letter {
    font-size: 8rem;
  }
}
/* @media screen and (max-width: 1200px) {
  .p-letter {
    font-size: 4rem;
  }
} */
@media screen and (max-width: 800px) {
  .p-letter {
    font-size: 3rem;
  }
}
@media screen and (max-width: 500px) {
  .p-letter {
    font-size: 2rem;
  }
}
