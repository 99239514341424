.app__cardImage {
  width: 100%;
  height: 100%;
}
.app__cardImage-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.app__cardImage img {
  width: 100%;
  height: 100%;
}
.img-anim {
  opacity: 0;
}
.img-anim.animate {
  animation: img-appearance 0s 1s forwards;
}
.span-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: bisque;
}
.span-element-anim {
  transform: translateX(-100%);
}
.span-element-anim.animate {
  transform: translateX(-100%);
  animation: slide-in 0.75s ease-out forwards,
    slide-out 0.75s 1s ease-out forwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  100% {
    transform: translateX(100%);
  }
}
@keyframes img-appearance {
  100% {
    opacity: 1;
  }
}
