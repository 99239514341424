.app__skill {
  width: 48%;
  position: relative;
}
.app__skill-container_title {
  width: fit-content;
}
.app__skill h4 {
  font-size: 1.6rem;
  color: var(--color-white);
  font-family: var(--font-base);
  letter-spacing: 1.2px;
  line-height: 1.23;
  text-transform: uppercase;
}
.app__skill-box-img {
  position: absolute;
  top: -50%;
  left: 50px;
  opacity: 0;
  transition: all 0.5s ease;
  width: 0px;
  height: 70px;
  z-index: 1;
  background-color: var(--color-white);
}
.app__skill-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__skill:hover .app__skill-box-img {
  opacity: 1;
  width: 100px;
}
@media screen and (max-width: 1200px) {
  .app__skill h4 {
    font-size: 1.5rem;
    letter-spacing: 1px;
    line-height: 1;
  }
}
@media screen and (max-width: 1000px) {
  .app__skill {
    width: fit-content;
  }
  .app__skill h4 {
    font-size: 1.3rem;
  }
  .app__skill-box-img {
    top: -80px;
    left: 0px;
  }
}
@media screen and (max-width: 850px) {
  .app__skill h4 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 850px) {
  .app__skill h4 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 550px) {
  .app__skill h4 {
    font-size: 1rem;
  }
  .app__skill-box-img {
    top: -45px;
    left: 0px;
    height: 40px;
  }
  .app__skill:hover .app__skill-box-img {
    opacity: 1;
    width: 60px;
  }
}
