.app__skills {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 7rem;
  margin-top: 4rem;
  padding-bottom: 2rem;
  /* background-color: rebeccapurple; */
  overflow: hidden;
}
.app__skills-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  /* background-color: rgb(129, 167, 58); */
}
.app__skill-container-left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* background-color: aqua; */
}
.app__skill-container-left_img {
  height: auto;
  width: 90%;
}
.app__skill-container-right {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.app__skill-right_box-one {
  flex: 1;
  height: 100%;
}
.app__skill-right_box-two {
  flex: 1;
  height: 100%;
  /* background-color: rgb(73, 41, 3); */
}
@media screen and (max-width: 1200px) {
  .app__skills {
    width: 100%;
    height: 80vh;
  }
}
@media screen and (max-width: 1000px) {
  .app__skills {
    width: 100%;
    height: auto;
    gap: 5rem;
    margin-top: 0rem;
  }
  .app__skills-container {
    flex-direction: column;
  }
  .app__skill-container-right {
    flex: 1;
    /* height: auto; */
    /* display: flex;
    flex-wrap: wrap; */
    gap: 1.5rem;
  }
}
@media screen and (max-width: 850px) {
  .app__skills {
    gap: 3rem;
  }
}
@media screen and (max-width: 550px) {
  .app__skill-container-left_img {
    width: 100%;
  }
}
