.app__aboutMe {
  width: 100%;
  height: 70vh;
  display: flex;
  margin-bottom: 2.5rem;
}
.app__aboutMe-left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.app__aboutMe-left-img {
  height: 90%;
}
.app__aboutMe-right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app__aboutMe-right p {
  font-size: 20px;
  font-family: var(--font-base);
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: var(--color-white);
}
@media screen and (max-width: 1000px) {
  .app__aboutMe {
    height: 60vh;
  }
  .app__aboutMe-right p {
    font-size: 17px;
  }
}
@media screen and (max-width: 850px) {
  .app__aboutMe {
    height: 60vh;
  }
  .app__aboutMe-right p {
    font-size: 17px;
  }
}
@media screen and (max-width: 750px) {
  .app__aboutMe {
    height: auto;
    gap: 0.2rem;
    flex-direction: column;
  }
  .app__aboutMe-left-img {
    width: 90%;
    height: 100%;
  }
  .app__aboutMe-right p {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 550px) {
  .app__aboutMe {
    margin-bottom: 0rem;
  }
}
