.app__projects {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.initial-bg {
  background-color: var(--color-black);
  transition: background 0.3s ease;
}

.new-bg {
  background-color: var(--color-grey-one);
  transition: background 0.3s ease;
}

.app__projects-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 3rem;
}
