.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
}
.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: var(--color-white);
}

@media screen and (min-width: 2000px) {
  .custom__button {
    font-size: 37px;
    line-height: 67px;
  }
}
@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}
@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
}
.text-initial-color {
  color: var(--color-black);
  transition: color 0.5s ease;
}
.text-new-color {
  color: var(--color-white);
  transition: color 0.5s ease;
}
.sp-new-bg {
  background-color: var(--color-black);
  transition: background 0.3s ease;
}
.sp-initial-bg {
  background-color: var(--color-white);
  transition: background 0.3s ease;
}

.color-white {
  color: var(--color-white);
}
.color-black {
  color: var(--color-black);
}
.app__goToTop {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: brown;
  z-index: 5;
  border-radius: 5px;
}
.app__goToHome {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 5px;
  background-color: var(--color-crimson);
}
@media screen and (max-width: 850px) {
  .app__goToTop {
    width: 30px;
    height: 30px;
  }
  .app__goToHome {
    font-size: 1.5rem;
  }
}
