.app__footer {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app__footer-box_titles {
  display: flex;
  justify-content: space-between;
}
.app__footer-box_titles h3 {
  font-size: 2rem;
  font-family: var(--font-base);
  font-weight: 100;
}
.app__footer-box-email {
  position: relative;
  display: flex;
  width: 100%;
}
.footer-arrow {
  position: absolute;
  top: 0%;
  left: 0;
  transform: rotate(35deg);
  font-size: 4rem;
}
.footer-email {
  font-size: 3rem;
  font-family: var(--font-base);
  letter-spacing: 4px;
  line-height: 1.4;
  padding-left: 10rem;
}
.app__footer-box-copyright {
  display: flex;
  justify-content: space-between;
}
.app__footer-box-copyright p {
  font-size: 15px;
  font-family: var(--font-base);
  font-weight: 100;
  letter-spacing: 1px;
  opacity: 0.67;
}

@media screen and (max-width: 1000px) {
  .app__footer-box_titles h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 750px) {
  .app__footer-box_titles h3 {
    font-size: 1rem;
  }
  .footer-arrow {
    font-size: 3rem;
  }
  .footer-email {
    font-size: 2rem;
    padding-left: 5rem;
  }
}
@media screen and (max-width: 550px) {
  .app__footer-box_titles {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  .app__footer-box_titles h3 {
    font-size: 1rem;
  }
  .footer-arrow {
    font-size: 2rem;
  }
  .footer-email {
    font-size: 1.5rem;
    padding-left: 3rem;
  }
  .app__footer-box-copyright {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .app__footer-box-copyright p {
    font-size: 14px;
  }
}
